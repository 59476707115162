import React, { useState } from "react";
import CustomModal from "../../components/CustomModal";
import { Checkbox } from "antd";
import { Notification } from "../../components/Notification";

const TermAndConditions = () => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isReaded, setIsReaded] = useState(false);
  const onChange = (e) => {
    setIsReaded(e.target.checked)
  };
  const handleConfirm = () => {
    if (isReaded) {
      localStorage.setItem("isTCAccpected", true);
      window.location.reload();
    } else {
      Notification.error('Please accept the privacy policy')
    }
  }

  return (
    <div className="w-full h-full flex justify-around items-center flex-col px-[16px]">
      <CustomModal
        width={883}
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <p className="text-[24px] font-[500] text-center text-black font-roboto">
          Privacy policy{" "}
        </p>
        <div className="h-[216px] overflow-y-auto mt-[12px]">
          <p className="text-[12px] font-[400] leading-[18px] text-left text-black font-roboto">
            Mi nunc ultricies molestie odio massa. Elementum sit venenatis urna
            tincidunt. Morbi amet auctor amet pellentesque. Aliquam odio viverra
            arcu elementum urna. Amet id amet pellentesque et pharetra eget
            ligula. Libero mattis nulla id ante cursus amet. Libero amet mi in
            in egestas. <br /> <br /> Lectus blandit vulputate eget odio. Velit
            tempus blandit tempus pellentesque cursus porttitor adipiscing.
            Ipsum pretium sit fusce varius commodo nibh nisl. Faucibus dolor est
            vitae pharetra etiam diam id vel. Ultricies eget potenti aliquam
            amet.
          </p>
        </div>
        <Checkbox onChange={onChange} value={isReaded}>
          <p className="text-[12px] font-[400] leading-[18px] text-left text-black font-roboto">
            I have Read the document and agree the Privacy policy{" "}
          </p>
        </Checkbox>

        <div className="flex gap-[8px] mt-[24px]">
          <button className="bg-white border h-[42px] border-gray-400 rounded-lg font-roboto text-[14px] font-normal leading-[21px] text-center w-full">
            Decline
          </button>

          <button
            onClick={handleConfirm}
            className="bg-green-theme text-white border h-[42px] rounded-lg font-roboto text-[14px] font-normal leading-[21px] text-center w-full"
          >
            Confirm
          </button>
        </div>
      </CustomModal >
    </div >
  );
};

export default TermAndConditions;
