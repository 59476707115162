import React, { useState } from "react";
import Header from "../components/Header";
import logo from "../assets/images/logo.png";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const PrivateLayout = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div className="flex h-screen bg-layout-theme ">
      <Sidebar visible={visible} onClose={onClose} />
      <div className="w-full">
        <div className="h-[67px]">
          <Header showDrawer={showDrawer} />
        </div>

        <div
          className="overflow-y-auto w-full p-[16px]"
          style={{ height: "calc(100vh - 67px)" }}
        >
          <Outlet />
        </div>
      </div>
      <img
        src={logo}
        alt="Logo"
        className="hidden min-1025:block w-1/2 h-full object-contain backdrop-blur-sm bg-black/20 p-[20px]"
      />
    </div>
  );
};

export default PrivateLayout;
