import React from "react";
import { Outlet } from "react-router-dom";
import authImage from "../assets/images/authImage.jpg";
import logo from "../assets/images/logo.png";

export default function PublicLayout() {
  return (
    <div className="flex h-screen">
      <div className="relative w-full h-full flex flex-col">
        <img
          src={authImage}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 z-10"></div>
        <div className="relative w-full h-full overflow-y-auto z-20 flex flex-col items-center justify-center px-4">
          <Outlet />
        </div>
      </div>
      <img
        src={logo}
        alt="Logo"
        className="hidden min-1025:block w-1/2 h-full object-contain backdrop-blur-sm bg-black/20 p-[20px]"
      />
    </div>
  );
}
