import axios from "axios";
import { store } from "../store/Store";
import { logout } from "../store/slices/loginSlice";

// const BaseUrl = import.meta.env.VITE_BACKEND_URL;
// const BaseUrl = "http://localhost:5000/"
const BaseUrl = "https://api.akinvestments.co.in/"

const Api = axios.create({
  timeout: 1000000,
  baseURL: BaseUrl,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

Api.interceptors.request.use(
  (config) => {
    if (store?.getState()?.loginSlice?.token) {
      const token = `Bearer ${store?.getState()?.loginSlice?.token}`;
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const dispatch = store.dispatch;
    if (error.response?.status === 401) {
      dispatch(logout())
    }
    return Promise.reject(error);
  }
);

export default Api;
