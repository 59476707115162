import { Divider } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const FamilyDetail = () => {
  const userDetails = useSelector((state) => state?.loginSlice?.data?.familyDetails);
  const [memberData, setMemberData] = useState([
    {
      id: 1,
      data: [
        { label: "Name", value: "Vijay Rawat" },
        { label: "Date of Birth", value: "28/12/1994" },
        { label: "Relation", value: "Brother" },
      ],
    },
    {
      id: 2,
      data: [
        { label: "Name", value: "Anita Verma" },
        { label: "Date of Birth", value: "15/07/1992" },
        { label: "Relation", value: "Sister" },
      ],
    },
  ]);

  useEffect(() => {
    let filteredFamilyDetails = userDetails?.map((el) => ({
      id: el?._id,
      data: [
        { label: "Name", value: el?.name },
        { label: "Date of Birth", value: dayjs(el?.dateOfBirth)?.format('DD-MM-YYYY') },
        { label: "Relation", value: el?.relation },
      ],
    }));
    setMemberData(filteredFamilyDetails);
  }, []);

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="text-[24px] font-[700] text-left text-black font-roboto  mt-[12px]">
        Family Details
      </p>
      {memberData.map((member, memberIndex) => (
        <div
          key={member.id}
          className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]"
        >
          <p className="text-[14px] font-[700] text-black font-roboto">
            Member {memberIndex + 1}
          </p>

          {member.data.map((item, index) => (
            <React.Fragment key={index}>
              <div className="flex justify-between">
                <p className="text-[14px] font-[400] text-gray-500 font-roboto">
                  {item.label}
                </p>
                <p className="text-[14px] font-[400] text-black font-roboto">
                  {item.value}
                </p>
              </div>
              {index !== member.data.length - 1 && (
                <Divider
                  style={{
                    margin: "0px",
                    height: "1px",
                    backgroundColor: "#E5E5E5",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FamilyDetail;
