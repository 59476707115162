import { Divider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { decryptSecretHex } from "../../utils/commonFunc";

const BankDetail = () => {
  const bank = useSelector((state) => state?.loginSlice?.data?.bankDetails);
  return (
    <div>
      <p className="text-[24px] font-[700] text-left text-black font-roboto mb-[10px] mt-[12px]">
        Bank Details
      </p>
      <div className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]">
        <div className="flex justify-between">
          <p className="text-[14px] font-[400] text-gray-500 font-roboto">
            Bank Details
          </p>
          <p className="text-[14px] font-[400] text-black font-roboto">
            {decryptSecretHex(bank?.accountNumber) ?? 'N/A'}
          </p>
        </div>
        <Divider
          style={{
            margin: "0px",
            height: "1px",
            backgroundColor: "#E5E5E5",
          }}
        />
        <div className="flex justify-between">
          <p className="text-[14px] font-[400] text-gray-500 font-roboto">
            Bank
          </p>
          <p className="text-[14px] font-[400] text-black font-roboto">
            {bank?.bankName}
          </p>
        </div>
        <Divider
          style={{
            margin: "0px",
            height: "1px",
            backgroundColor: "#E5E5E5",
          }}
        />
        <div className="flex justify-between">
          <p className="text-[14px] font-[400] text-gray-500 font-roboto">
            IFSC Code.
          </p>
          <p className="text-[14px] font-[400] text-black font-roboto">
            {decryptSecretHex(bank?.ifscCode) ?? 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BankDetail;
