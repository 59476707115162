import React, { useCallback, useEffect, useState } from "react";
import Search from "antd/es/transfer/search";
import { FilterIcon } from "../../utils/SVG";
import { Select } from "antd";
import SelfPolicy from "./Self";
import FamilyPolicy from "./Family";
import { listUserPolicies } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { Notification } from "../../components/Notification";
import { useSelector } from "react-redux";
import { Lowercase } from "../../utils/commonFunc";
import Claim from "./Claim";
const { Option } = Select;

const MyPolicy = () => {
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  const [selectedButton, setSelectedButton] = useState("self");
  const [initialLoading, setInitialLoading] = useState(true);
  const [policy, setPolicy] = useState([]);
  const [word, setWord] = useState("");
  const [searchData, setSearchData] = useState([]);

  const handleClick = (button) => {
    setSelectedButton(button);
  };

  const RenderViewContent = useCallback(() => {
    switch (selectedButton) {
      case "self":
        return <SelfPolicy data={word ? searchData : policy} initialLoading={initialLoading} />;
      case "family":
        return <FamilyPolicy data={word ? searchData : policy} initialLoading={initialLoading} />;
      case "claim":
        return <Claim data={word ? searchData : policy} initialLoading={initialLoading} />
      default:
        return null;
    }
  }, [selectedButton, policy, initialLoading, searchData, word]);

  const fetchPolicy = async () => {
    setInitialLoading(true);
    let params = new URLSearchParams();
    params.append("page", 1);
    params.append("limit", 100);

    let res = await listUserPolicies(userDetails?._id, params);
    if (res?.status === 200) {
      setPolicy(res?.data?.policies);
      setInitialLoading(false);
    } else {
      setInitialLoading(false);
      setPolicy([]);
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
    }
  };
  
  useEffect(() => {
    if (word) {
      let searchedFound = policy?.filter(
        (el) =>
          Lowercase(el?.policyNumber)?.includes(Lowercase(word)) ||
          el?.premiumAmount?.toString().includes(word) ||
          el?.sumInsured?.toString().includes(word)
      );
      setSearchData(searchedFound);
      return;
    }
    setSearchData([]);
  }, [word]);

  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex flex-col gap-[14px]">
        <p className="text-[24px] font-[700] text-left text-black font-roboto">
          My Policy
        </p>

        <div className="flex justify-between">
          <div className="w-full flex p-[4px] bg-white rounded-[6px]">
            <button
              className={`flex-1 py-2 px-4 ${selectedButton === "self"
                ? "bg-green-theme text-white"
                : "bg-white"
                } rounded`}
              onClick={() => handleClick("self")}
            >
              Self
            </button>
            <button
              className={`flex-1 py-2 px-4 ${selectedButton === "family"
                ? "bg-green-theme text-white"
                : "bg-white"
                } rounded`}
              onClick={() => handleClick("family")}
            >
              Family
            </button>
            <button
              className={`flex-1 py-2 px-4 ${selectedButton === "claim"
                ? "bg-green-theme text-white"
                : "bg-white"
                } rounded`}
              onClick={() => handleClick("claim")}
            >
              Claim
            </button>
          </div>
        </div>

        {!initialLoading && (
          <div className="flex w-full justify-between items-center gap-[10px]">
            <div style={{ width: "100%" }}>
              <Search
                placeholder="Search..."
                allowClear
                size="large"
                prefixCls="custom-search"
                style={{ width: "100%" }}
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />
            </div>
            {/* <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Select
                style={{ height: "48px", borderRadius: "10px" }}
                placeholder="Sort By"
                suffixIcon={null}
              >
                <Option value="option1">Option 1</Option>
                <Option value="option2">Option 2</Option>
                <Option value="option3">Option 3</Option>
              </Select>
              <div className="cursor-pointer">
                <FilterIcon />
              </div>
            </div> */}
          </div>
        )}
      </div>

      <RenderViewContent />
    </div>
  );
};

export default MyPolicy;
