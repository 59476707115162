import { Divider } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Nominee = () => {
  const userDetails = useSelector((state) => state?.loginSlice?.data?.nomineeDetails);
  const [nomineeData, setNomineeData] = useState([]);

  // [
  //   {
  //     id: 1,
  //     data: [
  //       { label: "Nominee Name", value: "Vijay Rawat" },
  //       { label: "Nominee Date of Birth", value: "28/12/1994" },
  //       { label: "Nominee Relation", value: "Brother" },
  //       { label: "Medical illness", value: "Enter Medical illn" },
  //       { label: "Medicine Name", value: "Enter Medicine Name" },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     data: [
  //       { label: "Nominee Name", value: "Anita Verma" },
  //       { label: "Nominee Date of Birth", value: "15/07/1992" },
  //       { label: "Nominee Relation", value: "Sister" },
  //       { label: "Medical illness", value: "None" },
  //       { label: "Medicine Name", value: "None" },
  //     ],
  //   },
  // ]

  useEffect(() => {
    let filterNomineeDetails = userDetails?.map((el) => ({
      id: el?._id,
      data: [
        { label: "Nominee Name", value: el?.name },
        { label: "Nominee Date of Birth", value: dayjs(el?.dateOfBirth)?.format('DD-MM-YYYY') },
        { label: "Nominee Relation", value: el?.relation },
        { label: "Medical illness", value: el?.medicalHistories?.length > 0 ? 'IN-PROGRESS' : 'N/A' },
        { label: "Medicine Name", value: el?.medicalHistories?.length > 0 ? 'IN-PROGRESS' : 'N/A' },
      ],
    }))
    setNomineeData(filterNomineeDetails);
  }, []);

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="text-[24px] font-[700] text-left text-black font-roboto mt-[12px]">
        Nominees
      </p>
      {nomineeData?.length > 0 ? nomineeData.map((nominee, nomineeIndex) => (
        <div
          key={nominee.id}
          className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]"
        >
          <p className="text-[14px] font-[700] text-black font-roboto">
            Nominee {nomineeIndex + 1}
          </p>

          {nominee.data.map((item, index) => (
            <React.Fragment key={index}>
              <div className="flex justify-between">
                <p className="text-[14px] font-[400] text-gray-500 font-roboto">
                  {item.label}
                </p>
                <p className="text-[14px] font-[400] text-black font-roboto">
                  {item.value}
                </p>
              </div>
              {index !== nominee.data.length - 1 && (
                <Divider
                  style={{
                    margin: "0px",
                    height: "1px",
                    backgroundColor: "#E5E5E5",
                  }}
                />
              )}
            </React.Fragment>
          ))}

          <p className="text-[14px] font-[500] text-green-theme font-roboto underline">
            View Documents
          </p>
        </div>
      ))
        :
        <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
          <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
            No Nominees Found
          </div>
        </div>
      }
    </div>
  );
};

export default Nominee;
