import CryptoJS from "crypto-js";
import dayjs from "dayjs";
const secretkey = process.env.REACT_APP_CRYPTOSECRET;

export function formatIndianNumber(num) {
    if (num === undefined || num === null) return "0"; // Return "0" for undefined or null
    if (isNaN(num)) return num; // Return the input as is if it's not a valid number

    const strNum = num.toString();
    const [integerPart, decimalPart] = strNum.split(".");

    // Format the integer part in the Indian numbering system
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    const formattedInteger = otherDigits
        ? otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits
        : lastThreeDigits;

    // Return the formatted number with any decimal part
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}

export const decryptSecretHex = (value) => {
    var bytes = CryptoJS.AES.decrypt(value, secretkey);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const isValidURL = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;

export function getAcademicYear(year) {
    if (!year || !Number(year)) return 'N/A'
    const nextYear = (year + 1).toString();
    return `${year}-${nextYear.slice(-2)}`;
}

export function Lowercase(word) {
    if (typeof word === 'string') {
        return word?.toLowerCase();
    }
    return ''
}

export function getNextDueDate(policyStart, ppt, installments) {
    const policyStartDate = dayjs(policyStart);
    const policyEndDate = policyStartDate.add(ppt, "year");
    const today = dayjs();

    let nextDueDate = null;

    // Check installments for the current year
    for (const installment of installments) {
        const currentYear = today.year();
        const installmentDate = dayjs(`${currentYear}-${installment.dueMonth}-${installment.dayOfMonth}`);

        // Validate the installment date
        if (
            installmentDate.isAfter(today) && // After today's date
            installmentDate.isAfter(policyStartDate) && // After policy start date
            installmentDate.isBefore(policyEndDate) // Before policy end date
        ) {
            nextDueDate = installmentDate;
            break; // Found the next due date, exit loop
        }
    }

    // Check installments for the next year if no valid due date this year
    if (!nextDueDate) {
        for (const installment of installments) {
            const nextYear = today.year() + 1;
            const installmentDateNextYear = dayjs(`${nextYear}-${installment.dueMonth}-${installment.dayOfMonth}`);

            if (
                installmentDateNextYear.isAfter(policyStartDate) && // After policy start date
                installmentDateNextYear.isBefore(policyEndDate) // Before policy end date
            ) {
                nextDueDate = installmentDateNextYear;
                break; // Found the next due date, exit loop
            }
        }
    }
    console.log(nextDueDate, 'nextDueDate')
    return nextDueDate ? nextDueDate.format("DD/MM/YYYY") : null;
}


