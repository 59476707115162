import React, { useEffect, useState } from "react";
import { ButtonComp } from "../../components/ButtonComp";
import { Flex, Rate } from "antd";
import CustomModal from "../../components/CustomModal";
import AddFeedback from "./AddFeedback";
import { getErrorMessage } from "../../utils/errorMessage";
import { useSelector } from "react-redux";
import { feedbackList } from "../../service/Collections";
import { Notification } from "../../components/Notification";
import dayjs from "dayjs";
import { Loader } from "../../components/Loader";

// const feedbackData = [
//   {
//     title: "Request a Feature",
//     date: "July 7, 2024",
//     rating: 3,
//     comment:
//       "Leo mauris gravida magna porttitor felis vestibulum sit. Scelerisque phasellus adipiscing lacus donec enim. Et amet nibh et et augue. Nibh nunc odio magna hendrerit posuere. Et eget gravida scelerisque nibh vivamus turpis diam sed in. Ultricies tellus nulla et rhoncus.",
//   },
//   {
//     title: "Feature Suggestion",
//     date: "July 8, 2024",
//     rating: 4,
//     comment:
//       "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
// ];

const Feedback = () => {
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  const [showModal, setShowModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFeedbackDetails = async () => {
    setLoading(true);
    let params = new URLSearchParams();
    params.append('page', 1);
    params.append('perPage', 100);

    let res = await feedbackList(userDetails?._id, params);
    if (res?.status === 200) {
      setFeedbackData(res?.data?.allFeedbackList?.reverse() ?? []);
      setLoading(false);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
      setLoading(false);
    }
  };

  console.log(feedbackData, 'feedbackData')

  useEffect(() => {
    fetchFeedbackDetails();
  }, []);


  if (loading) {
    return (
      <div
        style={{ height: "calc(100vh - 99px)" }}
        className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
      >
        <Loader color={"#688540"} size={44} />
      </div>
    );
  }


  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex flex-col gap-[12px]">
        <p className="text-[24px] font-[700] text-left text-black font-roboto">
          Feedback
        </p>
        <ButtonComp
          textcard={"Add Feedback"}
          handleAction={() => setShowModal(true)}
        />
      </div>

      <div className="flex flex-col gap-[10px]">
        {feedbackData?.length > 0 ? feedbackData.map((feedback, index) => (
          <div
            key={index}
            className="flex flex-col gap-[12px] bg-white p-[16px] rounded-tl-[6px] rounded-tr-[6px]"
          >
            <div className="flex flex-col gap-[6px]">
              <p className="text-[16px] font-[500] text-left text-black font-roboto">
                {feedback?.title}
              </p>
              <Flex gap="middle">
                <Rate defaultValue={feedback?.rating} disabled />
              </Flex>
            </div>

            <div>
              <p className="text-[12px] font-[400] text-left text-black font-roboto">
                Feedback on: {dayjs(feedback?.date).format('DD MMM YYYY')}
              </p>
              <div className="border border-gray-300 p-[12px] rounded-[6px] mt-[6px]">
                <p className="min-h-[40px] text-[12px] leading-[18px] font-[400] text-left text-black font-roboto">
                  {feedback?.description}
                </p>
              </div>
            </div>
          </div>
        ))
          :
          <div style={{ height: "calc(100vh - 249px" }} className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col justify-center">
            <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
              No Feedback Found
            </div>
          </div>
        }
      </div>

      <div>
        <CustomModal
          isVisible={showModal}
          style={{ marginTop: "40px" }}
          onClose={() => setShowModal(false)}
        >
          <AddFeedback handleClose={setShowModal} fetchList={fetchFeedbackDetails} />
        </CustomModal>
      </div>
    </div>
  );
};

export default Feedback;
