import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { PolicyBackIcon, ViewPolicyExportIcon } from "../../utils/SVG";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonComp } from "../../components/ButtonComp";
import { useSelector } from "react-redux";
import { getErrorMessage } from "../../utils/errorMessage";
import {
  getSinglePolicy,
  updatePolicyRaiseClaim,
} from "../../service/Collections";
import { Notification } from "../../components/Notification";
import { getAcademicYear } from "../../utils/commonFunc";
import dayjs from "dayjs";
import { Loader } from "../../components/Loader";
import CustomModal from "../../components/CustomModal";
import RaiseQuery from "../query/RaiseQuery";

const ViewPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  const [initialLoading, setInitialLoading] = useState(true);
  const [policyData, setPolicyData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const fetchSinglePolicy = async () => {
    setInitialLoading(true);
    let policy_id = location?.state?.policy?.id;
    let res = await getSinglePolicy(policy_id, userDetails?._id);
    if (res?.status === 200) {
      let el = res.data;
      setPolicyData([
        { label: "Policy No.", value: el?.policyNumber },
        {
          label: "Status",
          value: el?.userQueries?.some((el) => el?.status === 'Pending') ? "Raise Query" : el?.status,
        },
        { label: "Year", value: getAcademicYear(el?.year) },
        { label: "Policy Owner", value: el?.policyOwner },
        { label: "Amount", value: el?.premiumAmount },
        { label: "Sum Insured", value: el?.sumInsured },
        { label: "Proposer", value: el?.proposer },
        { label: "Premium Freq.", value: el?.premiumFrequency },
        { label: "Plan Name", value: el?.planName },
        { label: "PT", value: el?.pt + " year" },
        { label: "PPT", value: el?.ppt + " year" },
        {
          label: "Policy Start",
          value: dayjs(el?.policyStart).format("DD/MM/YYYY"),
        },
        {
          label: "Next Due Date",
          value: dayjs(el?.maturityEndDate).format("DD/MM/YYYY"),
        },
        {
          label: "Maturity/End Date",
          value: dayjs(el?.maturityEndDate).format("DD/MM/YYYY"),
        },
        { label: "Raise Claim", value: el?.raiseClaim ? "YES" : "NO" },
      ]);
      setInitialLoading(false);
    } else {
      setInitialLoading(false);
      setPolicyData([]);
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
    }
  };

  const handleQuery = () => {
    setShowModal(true);
  };

  const handleClaim = async () => {
    const res = await updatePolicyRaiseClaim(location?.state?.policy?.id);
    if (res?.status === 200) {
      Notification.success(res?.message);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
    }
  };

  useEffect(() => {
    fetchSinglePolicy();
  }, []);

  if (initialLoading) {
    return (
      <div
        style={{ height: "calc(100vh - 99px)" }}
        className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
      >
        <Loader color={"#688540"} size={44} />
      </div>
    );
  }
  const raiseClaimValue = policyData.find(
    (item) => item?.label === "Raise Claim"
  )?.value;

  return (
    <div className="flex flex-col gap-[12px]">
      <div className="flex justify-start items-center gap-[7px]">
        <div onClick={() => navigate(-1)} className="cursor-pointer">
          <PolicyBackIcon />
        </div>
        <div>
          <p className="text-[14px] font-[500] text-left text-black font-roboto">
            My Policy/policy number
          </p>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-[24px] font-[700] text-left text-black font-roboto">
          Policy
        </p>
        {/* <ViewPolicyExportIcon /> */}
      </div>
      <div className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]">
        {policyData.map((item, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between gap-[30px]">
              <p className="text-[14px] font-[400] text-left text-gray-500 font-roboto">
                {item.label}
              </p>
              <p className="text-[14px] font-[400] text-black font-roboto capitalize text-end">
                {item.value}
              </p>
            </div>
            {index !== policyData.length - 1 && (
              <Divider
                style={{
                  margin: "0px",
                  height: "1px",
                  backgroundColor: "#E5E5E5",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <ButtonComp textcard={"Renew"} disabled={true} />
      {raiseClaimValue === "NO" && (
        <ButtonComp textcard={"Raise Claim"} handleAction={handleClaim} />
      )}
      <ButtonComp textcard={"Raise Query"} handleAction={handleQuery} />

      <div>
        <CustomModal
          isVisible={showModal}
          style={{ marginTop: "40px" }}
          onClose={() => setShowModal(false)}
        >
          <RaiseQuery
            handleClose={setShowModal}
            policy_id={location?.state?.policy?.id}
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default ViewPolicy;
