export const PhoneIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58685 5.90223C6.05085 6.86865 6.68337 7.77441 7.48443 8.57546C8.28548 9.37651 9.19124 10.009 10.1577 10.473C10.2408 10.5129 10.2823 10.5329 10.3349 10.5482C10.5218 10.6027 10.7513 10.5636 10.9096 10.4502C10.9542 10.4183 10.9923 10.3802 11.0685 10.304C11.3016 10.071 11.4181 9.95443 11.5353 9.87824C11.9772 9.59091 12.5469 9.59091 12.9889 9.87824C13.106 9.95443 13.2226 10.071 13.4556 10.304L13.5856 10.4339C13.9398 10.7882 14.117 10.9654 14.2132 11.1556C14.4046 11.534 14.4046 11.9809 14.2132 12.3592C14.117 12.5495 13.9399 12.7266 13.5856 13.0809L13.4805 13.186C13.1274 13.5391 12.9508 13.7156 12.7108 13.8505C12.4445 14.0001 12.0308 14.1077 11.7253 14.1068C11.45 14.1059 11.2619 14.0525 10.8856 13.9457C8.86333 13.3718 6.95509 12.2888 5.36311 10.6968C3.77112 9.10479 2.68814 7.19655 2.11416 5.17429C2.00735 4.79799 1.95395 4.60984 1.95313 4.33455C1.95222 4.02906 2.0598 3.6154 2.20941 3.34907C2.34424 3.10904 2.52078 2.9325 2.87386 2.57942L2.97895 2.47433C3.33325 2.12004 3.5104 1.94289 3.70065 1.84666C4.07903 1.65528 4.52587 1.65528 4.90424 1.84666C5.0945 1.94289 5.27164 2.12004 5.62594 2.47433L5.75585 2.60424C5.98892 2.83732 6.10546 2.95385 6.18165 3.07104C6.46898 3.51296 6.46898 4.08268 6.18165 4.52461C6.10546 4.6418 5.98892 4.75833 5.75585 4.9914C5.67964 5.06761 5.64154 5.10571 5.60965 5.15026C5.4963 5.30854 5.45717 5.53805 5.51165 5.72495C5.52698 5.77754 5.54694 5.81911 5.58685 5.90223Z"
        stroke="#696969"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9L21 9"
        stroke="#CECECE"
        stroke-width="2.4"
        stroke-linecap="round"
      />
      <line
        x1="2"
        y1="8.7883"
        x2="8.78823"
        y2="2.00007"
        stroke="#CECECE"
        stroke-width="2.4"
        stroke-linecap="round"
      />
      <line
        x1="1.2"
        y1="-1.2"
        x2="10.8"
        y2="-1.2"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 2 7.48535)"
        stroke="#CECECE"
        stroke-width="2.4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const ModalCloseIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1_3325)">
        <rect
          x="7"
          y="7"
          width="32"
          height="32"
          rx="16"
          fill="#688540"
          shape-rendering="crispEdges"
        />
        <path
          d="M29 17L17 29M17 17L29 29"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1_3325"
          x="0.599999"
          y="0.599999"
          width="49.0667"
          height="49.0667"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.13333" dy="2.13333" />
          <feGaussianBlur stdDeviation="4.26667" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1_3325"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1_3325"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const HeaderMenuIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12.5H21M3 6.5H21M3 18.5H15"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const UploadAttachmentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.1898 2H7.81976C4.17976 2 2.00977 4.17 2.00977 7.81V16.18C2.00977 19.82 4.17976 21.99 7.81976 21.99H16.1898C19.8298 21.99 21.9998 19.82 21.9998 16.18V7.81C21.9998 4.17 19.8298 2 16.1898 2Z"
        fill="#688540"
      />
      <path
        d="M12.1998 17.3799C11.4998 17.3799 10.7898 17.1099 10.2598 16.5799C9.73977 16.0599 9.44977 15.3699 9.44977 14.6399C9.44977 13.9099 9.73977 13.2099 10.2598 12.6999L11.6697 11.2899C11.9597 10.9999 12.4397 10.9999 12.7297 11.2899C13.0197 11.5799 13.0197 12.0599 12.7297 12.3499L11.3198 13.7599C11.0798 13.9999 10.9498 14.3099 10.9498 14.6399C10.9498 14.9699 11.0798 15.2899 11.3198 15.5199C11.8098 16.0099 12.5998 16.0099 13.0898 15.5199L15.3098 13.2999C16.5798 12.0299 16.5798 9.96994 15.3098 8.69994C14.0398 7.42994 11.9798 7.42994 10.7098 8.69994L8.28973 11.1199C7.77973 11.6299 7.49976 12.2999 7.49976 13.0099C7.49976 13.7199 7.77973 14.3999 8.28973 14.8999C8.57973 15.1899 8.57973 15.6699 8.28973 15.9599C7.99973 16.2499 7.51974 16.2499 7.22974 15.9599C6.43974 15.1699 6.00977 14.1199 6.00977 12.9999C6.00977 11.8799 6.43974 10.8299 7.22974 10.0399L9.64978 7.61992C11.4998 5.76992 14.5198 5.76992 16.3698 7.61992C18.2198 9.46992 18.2198 12.4899 16.3698 14.3399L14.1498 16.5599C13.6098 17.1099 12.9098 17.3799 12.1998 17.3799Z"
        fill="#688540"
      />
    </svg>
  );
};

export const AvatarEditIcon = () => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.4995" cy="11.9995" r="11.0152" fill="#688540" />
      <path
        opacity="0.4"
        d="M15.6305 16.5898H7.36913C7.18095 16.5898 7.0249 16.4338 7.0249 16.2456C7.0249 16.0574 7.18095 15.9014 7.36913 15.9014H15.6305C15.8187 15.9014 15.9747 16.0574 15.9747 16.2456C15.9747 16.4338 15.8187 16.5898 15.6305 16.5898Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M14.7215 8.08951C13.8312 7.19912 12.9591 7.17617 12.0458 8.08951L11.4904 8.64486C11.4445 8.69076 11.4262 8.76419 11.4445 8.82845C11.7933 10.0447 12.7664 11.0177 13.9826 11.3665C14.001 11.3711 14.0193 11.3757 14.0377 11.3757C14.0882 11.3757 14.1341 11.3573 14.1708 11.3206L14.7215 10.7653C15.1759 10.3155 15.3962 9.87948 15.3962 9.43887C15.4008 8.9845 15.1805 8.54389 14.7215 8.08951Z"
        fill="white"
      />
      <path
        d="M13.1566 11.7837C13.0235 11.7194 12.895 11.6552 12.7711 11.5817C12.6701 11.5221 12.5737 11.4578 12.4773 11.389C12.3993 11.3385 12.3075 11.2651 12.2203 11.1916C12.2111 11.187 12.179 11.1595 12.1423 11.1228C11.9908 10.9943 11.821 10.829 11.6696 10.6455C11.6558 10.6363 11.6328 10.6041 11.6007 10.5628C11.5548 10.5078 11.4768 10.416 11.408 10.3104C11.3529 10.2416 11.2886 10.1406 11.229 10.0396C11.1555 9.9157 11.0913 9.79178 11.027 9.66326C10.9628 9.52558 10.9123 9.39248 10.8664 9.26855L7.98407 12.1509C7.9244 12.2105 7.86933 12.3253 7.85556 12.4033L7.60772 14.1611C7.56182 14.4732 7.64902 14.767 7.84179 14.9643C8.00702 15.125 8.2365 15.2122 8.48434 15.2122C8.53942 15.2122 8.59449 15.2076 8.64957 15.1984L10.412 14.9505C10.4946 14.9368 10.6094 14.8817 10.6644 14.822L13.5467 11.9397C13.4182 11.8938 13.2943 11.8434 13.1566 11.7837Z"
        fill="white"
      />
    </svg>
  );
};

export const FilterIcon = () => {
  return (
    <svg
      width="44"
      height="48"
      viewBox="0 0 44 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="48" rx="10" fill="#688540" />
      <path
        d="M16 24H28M13 18H31M19 30H25"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const ViewPolicyExportIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="#688540" />
      <path
        d="M17.4025 10.6993L17.4025 7.09766M17.4025 7.09766H13.8009M17.4025 7.09766L12.0001 12.5001M10.7995 7.09766H9.47894C8.4704 7.09766 7.96612 7.09766 7.58091 7.29393C7.24207 7.46658 6.96658 7.74207 6.79393 8.08091C6.59766 8.46612 6.59766 8.9704 6.59766 9.97894V15.0212C6.59766 16.0297 6.59766 16.534 6.79393 16.9192C6.96658 17.2581 7.24207 17.5335 7.58091 17.7062C7.96612 17.9025 8.4704 17.9025 9.47894 17.9025H14.5212C15.5297 17.9025 16.034 17.9025 16.4192 17.7062C16.7581 17.5335 17.0335 17.2581 17.2062 16.9192C17.4025 16.534 17.4025 16.0297 17.4025 15.0212V13.7006"
        stroke="white"
        stroke-width="1.20053"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const PolicyBackIcon = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 0.5L-2.40413e-07 6L6 11.5L6 0.5Z" fill="#111111" />
    </svg>
  );
};
