import { Form, Input } from "antd";
import React, { useState } from "react";
import { ButtonComp } from "../../components/ButtonComp";
import { submitQuery } from "../../service/Collections";
import { useSelector } from "react-redux";
import { getErrorMessage } from "../../utils/errorMessage";
import { Notification } from "../../components/Notification";

export default function RaiseQuery({ handleClose, policy_id }) {
    const userDetails = useSelector((state) => state?.loginSlice?.data);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        let payload = {
            userId: userDetails?._id,
            policyId: policy_id,
            description: values?.description,
        };

        let res = await submitQuery(payload);
        if (res?.status === 200) {
            Notification.success(res?.message || "Your Query is successfully submitted, We will reached you out shortly!");
            setLoading(false);
            handleClose(false);
        } else if (res?.status === 400) {
            Notification.warning(res?.message);
            setLoading(false);
            handleClose(false);
        } else {
            const message = getErrorMessage(res, "Failed to connection");
            Notification.error(message);
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center">
            <h1 className="text-[24px] font-[600] text-black font-roboto mb-[16px]">
                Raise Query
            </h1>

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                className="w-full"
            >
                <Form.Item
                    label=""
                    name="description"
                    style={{ marginBottom: "28px" }}
                    rules={[
                        { required: true, message: "Please explain your query!" },
                        {
                            validator: (_, value) => {
                                if (!value || (value.length >= 10 && value.length <= 200)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("Query must be between 10 and 200 characters.")
                                );
                            },
                        },
                    ]}
                >
                    <Input.TextArea
                        rows={4}
                        placeholder="Write a text here..."
                        style={{ resize: "none" }}
                    />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <ButtonComp isLoading={loading} textcard={"Submit"} />
                </Form.Item>
            </Form>
        </div>
    );
}
