import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdCheckCircle, MdError, MdWarning, MdClose } from 'react-icons/md';
import '../App.css'; // Custom animations

export const Notification = {
    success: (message) => {
        toast.success(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdCheckCircle style={{ marginRight: '8px' }} size={24} color="#34D399" />
                {message}
            </div>,
            {
                style: {
                    backgroundColor: '#e8f5e9',
                    color: '#34D399',
                    fontWeight: '500',
                    fontSize: '16px',
                    borderRadius: '10px',
                    boxShadow: '0 6px 18px rgba(52, 211, 153, 0.2)',
                    padding: '12px 16px',
                },
                progressStyle: {
                    background: 'linear-gradient(90deg, #34D399, #10B981)',
                    height: '4px',
                    borderRadius: '0 0 10px 10px',
                },
                icon: false,
                closeButton: <MdClose color="#34D399" size={18} />,
            }
        );
    },
    error: (message) => {
        toast.error(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdError style={{ marginRight: '8px' }} size={24} color="#EF4444" />
                {message}
            </div>,
            {
                style: {
                    backgroundColor: '#fce4e4',
                    color: '#EF4444',
                    fontWeight: '500',
                    fontSize: '16px',
                    borderRadius: '10px',
                    boxShadow: '0 6px 18px rgba(239, 68, 68, 0.2)',
                    padding: '12px 16px',
                },
                progressStyle: {
                    background: 'linear-gradient(90deg, #EF4444, #DC2626)',
                    height: '4px',
                    borderRadius: '0 0 10px 10px',
                },
                icon: false,
                closeButton: <MdClose color="#EF4444" size={18} />,
            }
        );
    },
    warning: (message) => {
        toast.warn(
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <MdWarning style={{ marginRight: '8px' }} size={24} color="#F59E0B" />
                {message}
            </div>,
            {
                style: {
                    backgroundColor: '#FFFBEB',
                    color: '#F59E0B',
                    fontWeight: '500',
                    fontSize: '16px',
                    borderRadius: '10px',
                    boxShadow: '0 6px 18px rgba(245, 158, 11, 0.2)',
                    padding: '12px 16px',
                },
                progressStyle: {
                    background: 'linear-gradient(90deg, #F59E0B, #FBBF24)',
                    height: '4px',
                    borderRadius: '0 0 10px 10px',
                },
                icon: false,
                closeButton: <MdClose color="#F59E0B" size={18} />,
            }
        );
    },
};

// Don't forget to include <ToastContainer /> in your App.js or main component
