import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { ButtonComp } from "../../components/ButtonComp";
import { Input } from "antd";
import { BackIcon } from "../../utils/SVG";
import { useLocation, useNavigate } from "react-router-dom";
import { login as resendOtpService, verifyOtp } from "../../service/Collections"; // Assume resendOtpService is implemented
import { getErrorMessage } from "../../utils/errorMessage";
import { Notification } from "../../components/Notification";
import { useDispatch } from "react-redux";
import { loginAuth, updatedToken } from "../../store/slices/loginSlice";

const VerificationOtp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    const timer =
      resendTimer > 0 &&
      setInterval(() => setResendTimer(resendTimer - 1), 1000);

    return () => clearInterval(timer);
  }, [resendTimer]);

  const handleChange = (value, index) => {
    const newOtp = [...otp];

    if (value.match(/^[0-9]$/)) {
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else if (value === "") {
      newOtp[index] = "";
      setOtp(newOtp);
      if (index > 0) {
        const prevInput = document.getElementById(`otp-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleVerfiyOtp = async () => {
    if (otp.every(value => value === "")) {
      Notification.error("OTP is required, Please enter the OTP");
      return;
    }

    setLoading(true);
    let values = { phoneNumber: location?.state, otp: otp?.join("") };
    let res = await verifyOtp(values);
    setLoading(false);

    if (res?.status === 200) {
      Notification.success(res?.message);
      dispatch(loginAuth(res));
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
    }
  };

  const handleResendOtp = async () => {
    let values = { phoneNumber: location?.state };
    let res = await resendOtpService(values);
    if (res?.status === 200) {
      Notification.success("OTP has been resent");
      setLoading(false);
      setResendTimer(60);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
      setLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="w-full h-full flex justify-around items-center flex-col px-[16px]">
      <div
        className="flex justify-start w-full pl-[10px] cursor-pointer"
        onClick={() => navigate("/")}
      >
        <BackIcon />
      </div>
      <img src={logo} alt="" className="w-[236px] h-[60px]" />

      <div className="bg-white/10 backdrop-blur-sm py-[32px] px-[16px] flex flex-col gap-[32px] rounded-[16px]">
        <div className="flex flex-col gap-[8px]">
          <p className="text-[32px] font-[700] leading-[38px] text-center text-white font-roboto">
            Verification Code
          </p>
          <p className="text-[14px] font-[600] leading-[16px] text-center text-white font-roboto">
            We have sent the verification code to your email address
          </p>
        </div>

        <div className="flex justify-center gap-[12px]">
          {otp.map((_, index) => (
            <Input
              key={index}
              maxLength={1}
              value={otp[index]}
              onChange={(e) => handleChange(e.target.value, index)}
              className="w-[50px] h-[50px] text-center text-xl font-bold"
              id={`otp-${index}`}
              style={{ textAlign: "center" }}
            />
          ))}
        </div>

        <ButtonComp
          textcard={loading ? "Loading..." : "Verify OTP"}
          handleAction={handleVerfiyOtp}
          loading={loading}
        />

        <button
          onClick={handleResendOtp}
          disabled={resendTimer > 0}
          className={`${resendTimer > 0 ? "text-black-900" : "text-white"
            } text-sm font-medium mt-4`}
        >
          Resend OTP {resendTimer > 0 && `in ${formatTime(resendTimer)}`}
        </button>
      </div>

      <p className="text-[12px] font-[600] text-center text-white font-roboto">
        Terms & Condition | Privacy policy{" "}
      </p>
    </div>
  );
};

export default VerificationOtp;
