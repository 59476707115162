import React, { useState, useRef } from "react";
import { Form, Input, Button, Rate, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ButtonComp } from "../../components/ButtonComp";
import { UploadAttachmentIcon } from "../../utils/SVG";
import { submitFeedback } from "../../service/Collections";
import { getErrorMessage } from "../../utils/errorMessage";
import { Notification } from "../../components/Notification";
import { useSelector } from "react-redux";
import { uploadDocOnS3 } from "../../aws/commonFunc";
import { isValidURL } from "../../utils/commonFunc";

const AddFeedback = ({ handleClose, fetchList }) => {
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  const [form] = Form.useForm();
  const [rating, setRating] = useState(0);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);


  const onFinish = async (values) => {
    setLoading(true);

    if (rating === 0) {
      Notification.error('Please give your valuable rating!');
      return
    }

    let payload = {
      userId: userDetails?._id,
      title: values?.title,
      description: values?.description,
      rating: rating,
      attachment: "",
    }

    if (file) {
      let uploadedURL = await uploadDocOnS3(file);
      if (isValidURL?.test(uploadedURL)) {
        payload.attachment = uploadedURL;
      } else {
        setLoading(false);
        return
      }
    }

    let res = await submitFeedback(payload);
    if (res?.status === 200) {
      Notification.success(res?.message);
      setLoading(false);
      handleClose(false);
      fetchList();
      // CALL LISTING API
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const removeFile = () => {
    setFile(null);
    setFileName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-[24px] font-[600] text-black font-roboto mb-[16px]">
        Add Feedback
      </h1>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="w-full"
      >
        <Form.Item style={{ marginBottom: "12px" }}>
          <Rate value={rating} onChange={setRating} />
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          style={{ marginBottom: "16px" }}
          rules={[{ required: true, message: "Please input your title!" }]}
        >
          <Input className="h-[48px]" placeholder="Write a text here..." />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          style={{ marginBottom: "6px" }}
          rules={[
            { required: true, message: "Please input your description!" },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder="Write a text here..."
            style={{ resize: "none" }}
          />
        </Form.Item>

        {/* <Form.Item>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="*"
          />

          {!fileName && (
            <div className="flex gap-[4px] justify-end ">
              <div onClick={handleUploadClick} className="cursor-pointer">
                <UploadAttachmentIcon />
              </div>
              <p
                onClick={handleUploadClick}
                className="text-[14px] font-[400] text-green-theme font-inter underline cursor-pointer"
              >
                Add Attachment
              </p>
            </div>
          )}

          {fileName && (
            <div className="flex items-center justify-between border border-gray-300 rounded-[4px] p-[8px] mt-[8px]">
              <span className="text-red-500">{fileName}</span>
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={removeFile}
                className="text-red-500"
              />
            </div>
          )}
        </Form.Item> */}

        <Form.Item style={{ marginBottom: 0 }}>
          <ButtonComp isLoading={loading} textcard={"Submit"} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddFeedback;
