import React from "react";
import { Drawer, Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import sideBarLogo from "../assets/images/sideBarLogo.png";
import { CgProfile } from "react-icons/cg";
import { BiMessageRoundedError } from "react-icons/bi";
import { FaWpforms } from "react-icons/fa6";
import { SlCallEnd } from "react-icons/sl";
import { useNavigate, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlinePolicy } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/loginSlice";

const Sidebar = ({ visible, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
const dispatch = useDispatch();

  const menuItems = [
    {
      icon: <AiOutlineHome />,
      label: "Home",
      path: "/home",
    },
    {
      icon: <MdOutlinePolicy />,
      label: "My Policy",
      path: "/myPolicy",
    },
    {
      icon: <CgProfile />,
      label: "My Profile",
      path: "/myProfile",
    },
    {
      icon: <BiMessageRoundedError />,
      label: "Feedback",
      path: "/feedback",
    },
    {
      icon: <FaWpforms />,
      label: "About A.K Investment",
    },
    {
      icon: <SlCallEnd />,
      label: "Contact Us",
    },
  ];

  const handleNavigation = (item) => {
    if (item.path) {
      navigate(item.path);
      onClose();
    } else {
      if (item?.label === 'Contact Us') {
        window.location.href = `tel:${8447118742}`;
        return;
      }
      window.open("https://akinvestments.co.in/", "_blank");
    }

  }

  const handleLogOut = () => {
    dispatch(logout())
  };

  return (
    <Drawer
      placement="left"
      onClose={onClose}
      open={visible}
      bodyStyle={{ padding: 0 }}
      width={250}
      closable={false}
      className="p-0"
    >
      <div className="flex flex-col h-full px-[16px] py-[24px]">
        <div className="flex flex-col gap-[24px]">
          <div className="flex flex-col">
            <img src={sideBarLogo} alt="" className="w-[157px] h-[40px]" />
          </div>

          <div className="flex flex-col gap-[5px]">
            {menuItems.map((item, index) => {
              const isActive = location.pathname === item.path;
              return (
                <div
                  key={index}
                  className={`flex gap-[19px] items-center h-[48px] ${isActive ? "text-white bg-green-theme" : "text-black"
                    } rounded-[10px] px-[19px] cursor-pointer`}
                  onClick={() => handleNavigation(item)}
                >
                  {item.icon}
                  <p className="text-[14px] font-[400] font-roboto">
                    {item.label}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center mt-auto">
          <Button
            type="primary"
            danger
            className="w-full flex items-center justify-start h-[50px]"
            icon={<LogoutOutlined />}
            onClick={() => handleLogOut()}
          >
            Logout
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
