import { deleteApi, getApi, postApi } from "./API_Methods";

// Auth API

const LOGIN = "/user/login";

export const login = (payload) => {
  return postApi(LOGIN, payload);
};

const VERIFYOTP = "/user/verify-otp";

export const verifyOtp = (payload) => {
  return postApi(VERIFYOTP, payload);
};

// Home Section

const USER_PROFILE = "/user/profile";
export function getClientProfile(params, type) {
  return getApi(`${USER_PROFILE}/${params}?${type}`);
}

// Feedback Module

const SUBMIT_FEEDBACK = "/user/submit-feedback";
export function submitFeedback(payload) {
  return postApi(SUBMIT_FEEDBACK, payload);
}

const FEEDBACK_LIST = "/user/feedback-list";
export const feedbackList = (id, params) => {
  return getApi(`${FEEDBACK_LIST}/${id}?${params}`);
};

// Policy Module

const POLICY_LIST = "/policy/userPolicyList";
export const listUserPolicies = (id, params) => {
  return getApi(`${POLICY_LIST}/${id}?${params}`);
};

const SINGLE_POLICY = "/policy/userPolicy";
export const getSinglePolicy = (policyID, userID) => {
  return getApi(`${SINGLE_POLICY}/${policyID}/${userID}`);
};

const RAISE_CLAIM = "/policy/updatePolicyRaiseClaim";
export function updatePolicyRaiseClaim(policyId) {
  return getApi(`${RAISE_CLAIM}?policyId=${policyId}`);
}

// Query Module


const SUBMIT_QUERY = "/userQuery/submit";
export function submitQuery(payload) {
  return postApi(SUBMIT_QUERY, payload);
}