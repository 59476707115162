import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar, Divider } from "antd";
import React, { useState } from "react";
import MedicalHistory from "./MedicalHistory";
import Nominee from "./Nominee";
import FamilyDetail from "./FamilyDetail";
import BankDetail from "./BankDetail";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { formatIndianNumber } from "../../utils/commonFunc";

const MyProfile = () => {
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  // const [avatarImage, setAvatarImage] = useState(userDetails?.profile ?? null);
  // const [avatarLoading, setAvatarLoading] = useState(false);
  const [profileData, setProfileData] = useState([
    {
      label: "Date Of Birth",
      value: dayjs(userDetails?.dateOfBirth).format("DD MMM YYYY"),
    },
    { label: "Age", value: userDetails?.age },
    { label: "Gender", value: userDetails?.gender === 1 ? "Male" : "Female" },
    { label: "Phone Number", value: userDetails?.phoneNumber },
    {
      label: "Alternative Phone No.",
      value: userDetails?.alternatePhoneNumber || "NA",
    },
    { label: "Email", value: userDetails?.email },
    {
      label: "Height",
      value: `${userDetails?.physicalDetails?.height} Inches`,
    },
    { label: "Weight", value: `${userDetails?.physicalDetails?.weight} Kg` },
    { label: "Mother Name", value: userDetails?.motherName },
    { label: "Father Name", value: userDetails?.fatherName },
    { label: "Annual Income", value: userDetails?.annualIncome },
    { label: "Qualification", value: userDetails?.qualification },
    { label: "Nature of Business", value: userDetails?.businessNature },
    {
      label: "Permanent Address",
      value: `${userDetails?.permanentAddress?.streetAddress || ''},
       ${userDetails?.permanentAddress?.aptUnitSuite || ''}, 
       ${userDetails?.permanentAddress?.city || ''}, 
       ${userDetails?.permanentAddress?.state || ''} - ${userDetails?.permanentAddress?.zip || ''}`,
    },
  ]);
console.log(userDetails,'userDetails')
  // const handleFileChange = async (file) => {
  //   setAvatarLoading(true);
  //   await uploadDocOnS3(file).then((location) => {
  //     console.log("File uploaded at:", location);
  //     setAvatarImage(location)
  //     setAvatarLoading(false);
  //   })
  //     .catch((error) => {
  //       console.error("Error uploading file:", error.message);
  //       setAvatarImage(null);
  //       setAvatarLoading(false);
  //       Notification.error(error?.message || 'Error uploading file')
  //     });
  // };

  // const fetchProfileDetails = () => {

  // }

  // useEffect(()=> {
  //   fetchProfileDetails();
  // },[]);

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="text-[24px] font-[700] text-left text-black font-roboto">
        My Profile
      </p>

      <div className="bg-white rounded-[20px] flex flex-col justify-center items-center p-[24px] gap-[16px]">
        <div className=" relative w-[fit-content]">
          <Avatar
            size={200}
            src={userDetails?.profile}
            icon={!userDetails?.profile && <AntDesignOutlined />}
          />
          {/* <div
            className="absolute bottom-[13px] right-[15px] cursor-pointer"
            onClick={() => document.getElementById("avatarUpload").click()}
          >
            <AvatarEditIcon />
          </div>
          <input
            id="avatarUpload"
            type="file"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => handleFileChange(e.target.files[0])}
          /> */}
        </div>
        <div>
          <p className="text-[24px] font-[400] text-center text-black font-roboto">
            {userDetails?.firstName} {userDetails?.middleName} {userDetails?.lastName}
          </p>
          <p className="text-[14px] font-[400] text-center text-black font-roboto">
            {userDetails?.permanentAddress?.city},{" "}
            {userDetails?.permanentAddress?.state}
          </p>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              {userDetails?.policyInfo?.totalPolicies ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              No. of Policy
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              ₹
              {formatIndianNumber(
                userDetails?.policyInfo?.totalPremiumAmount
              ) ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              Total Premium
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              {userDetails?.policyInfo?.totalRaisedClaims ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              Total Claim
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]">
        {profileData.map((item, index) => (
          <React.Fragment key={index}>
            <div className="flex justify-between gap-[30px]">
              <p className="text-[14px] font-[400] text-left text-gray-500 font-roboto">
                {item.label}
              </p>
              <p className="text-[14px] font-[400] text-black text-end font-roboto">
                {item.value}
              </p>
            </div>
            {index !== profileData.length - 1 && (
              <Divider
                style={{
                  margin: "0px",
                  height: "1px",
                  backgroundColor: "#E5E5E5",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <MedicalHistory />
      <Nominee />
      <FamilyDetail />
      <BankDetail />
    </div>
  );
};

export default MyProfile;
