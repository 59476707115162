import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatIndianNumber, getAcademicYear, getNextDueDate } from "../../utils/commonFunc";
import dayjs from "dayjs";
import { Loader } from "../../components/Loader";

const SelfPolicy = ({ data, initialLoading }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [policies, setPolicies] = useState([]);

  useEffect(() => {
    setLoading(true);
    let filteredSelfPolicy = data
      ?.filter((el) => el?.policyOwner === "self")
      ?.map((el) => ({
        id: el?._id,
        policyNo: el?.policyNumber,
        status: el?.status,
        userQueriesLength: el?.userQueries?.some((el) => el?.status === 'Pending') ? 1 : 0,
        details: [
          { label: "Premium Freq.", value: el?.premiumFrequency },
          { label: "Type", value: el?.type },
          { label: "Year", value: getAcademicYear(el?.year) },
          {
            label: "Premium Amt.",
            value: formatIndianNumber(el?.premiumAmount),
          },
          {
            label: "Sum Insured",
            value: formatIndianNumber(el?.sumInsured),
          },
          {
            label: "End Date",
            value: dayjs(el?.maturityEndDate).format("DD/MM/YYYY"),
          },
          {
            label: "Next Due Date",
            value: getNextDueDate(el?.policyStart, el?.ppt, el?.installments),
          },
          {
            label: "Policy Start",
            value: dayjs(el?.policyStart).format("DD/MM/YYYY"),
          },
        ],
      }));
    setPolicies(filteredSelfPolicy);
    setLoading(false);
  }, []);

  // const policies = [
  //   {
  //     policyNo: "GJTG855525554GJK",
  //     status: "Active",
  //     details: [
  //       { label: "Premium Freq.", value: "Monthly" },
  //       { label: "Type", value: "PERSONAL ACCIDENT" },
  //       { label: "Year", value: "2024-25" },
  //       { label: "Premium Amt.", value: "₹1,50,000" },
  //       { label: "Sum Insured", value: "₹1,50,000" },
  //       { label: "End Date", value: "08/1/2025" },
  //       { label: "Next Due Date", value: "08/1/2024" },
  //       { label: "Policy Start", value: "08/1/2024" },
  //     ],
  //   },
  //   {
  //     policyNo: "KJHF998787HHH7",
  //     status: "Inactive",
  //     details: [
  //       { label: "Premium Freq.", value: "Quarterly" },
  //       { label: "Type", value: "HEALTH" },
  //       { label: "Year", value: "2023-24" },
  //       { label: "Premium Amt.", value: "₹2,00,000" },
  //       { label: "Sum Insured", value: "₹3,00,000" },
  //       { label: "End Date", value: "12/31/2024" },
  //       { label: "Next Due Date", value: "12/31/2023" },
  //       { label: "Policy Start", value: "12/31/2023" },
  //     ],
  //   },
  //   {
  //     policyNo: "JHTY554887OOO9",
  //     status: "Active",
  //     details: [
  //       { label: "Premium Freq.", value: "Yearly" },
  //       { label: "Type", value: "LIFE INSURANCE" },
  //       { label: "Year", value: "2025-26" },
  //       { label: "Premium Amt.", value: "₹1,75,000" },
  //       { label: "Sum Insured", value: "₹2,00,000" },
  //       { label: "End Date", value: "09/30/2025" },
  //       { label: "Next Due Date", value: "09/30/2024" },
  //       { label: "Policy Start", value: "09/30/2024" },
  //     ],
  //   },
  // ];

  const handlePolicyClick = (policy) => {
    navigate("/viewPolicy", { state: { policy } });
  };

  if (initialLoading || loading) {
    return (
      <div
        style={{ height: "calc(100vh - 283px)" }}
        className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
      >
        <Loader color={"#688540"} size={44} />
      </div>
    );
  }

  if (policies?.length === 0) {
    return (
      <div
        style={{ height: "calc(100vh - 283px)" }}
        className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
      >
        <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
          No Data Found
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[10px]">
      {policies?.map((policy, policyIndex) => (
        <div
          key={policyIndex}
          className="bg-white p-[16px] rounded-[6px] cursor-pointer"
          onClick={() => handlePolicyClick(policy)}
        >
          <div className="flex justify-between">
            <div className="flex flex-col gap-4px]">
              <p className="text-[14px] font-[400] text-gray-500 font-roboto">
                Policy No.
              </p>
              <p className="text-[14px] font-[500] text-black font-roboto">
                {policy?.policyNo}
              </p>
            </div>

            {policy?.userQueriesLength > 0 ? (
              <div
                className={` bg-[#FFF3DB] rounded-[100px] px-[12px] py-[8px] h-[24px] w-[fit-content] flex justify-center items-center`}
              >
                <p
                  className={`text-[12px] font-[600] text-[#FFC023] font-roboto`}
                >
                  {"Raise Query"}
                </p>
              </div>
            ) : (
              <div
                className={`${policy?.status === "active" ? "bg-[#DFFFDE]" : "bg-red-200"
                  } rounded-[100px] px-[12px] py-[8px] h-[24px] w-[fit-content] flex justify-center items-center`}
              >
                <p
                  className={`text-[12px] font-[600] ${policy?.status === "active"
                    ? "text-[#5EB85C]"
                    : "text-[#FF5E5E]"
                    } font-roboto`}
                >
                  {policy?.status.charAt(0).toUpperCase() +
                    policy?.status.slice(1)}
                </p>
              </div>
            )}
          </div>

          <Divider
            style={{
              margin: "16px 0px",
              height: "1px",
              backgroundColor: "#E5E5E5",
            }}
          />

          <div className="flex flex-col gap-[16px]">
            <div className="flex justify-between">
              {policy.details?.slice(0, 2)?.map((detail, index) => (
                <div key={index} className="flex flex-col gap-4px]">
                  <p className="text-[14px] font-[500] text-black font-roboto">
                    {detail.label}
                  </p>
                  <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize ">
                    {detail.value}
                  </p>
                </div>
              ))}
            </div>

            <div className="flex justify-between">
              {policy.details?.slice(2, 5)?.map((detail, index) => (
                <div key={index} className="flex flex-col gap-4px]">
                  <p className="text-[14px] font-[500] text-black font-roboto">
                    {detail.label}
                  </p>
                  <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize text-center">
                    {detail.value}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <Divider
            style={{
              margin: "16px 0px",
              height: "1px",
              backgroundColor: "#E5E5E5",
            }}
          />

          <div className="flex justify-between">
            {policy.details?.slice(5, 8)?.map((detail, index) => (
              <div key={index} className="flex flex-col gap-4px]">
                <p className="text-[14px] font-[500] text-black font-roboto">
                  {detail.label}
                </p>
                <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize text-center">
                  {detail.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelfPolicy;
