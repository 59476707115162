import React, { useCallback, useEffect, useState } from "react";
import { Avatar } from "antd";
import { LeftOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getClientProfile } from "../../service/Collections";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "../../utils/errorMessage";
import { Notification } from "../../components/Notification";
import { formatIndianNumber } from "../../utils/commonFunc";
import { policyCategory, policyMonthCounts } from "../../utils/eNums";
import { Loader } from "../../components/Loader";
import { updatedData } from "../../store/slices/loginSlice";

const StatDisplay = ({ title, count, isUpperCase }) => (
  <div className="flex justify-between">
    <p
      className={`text-[14px] font-[400] text-gray-600 font-roboto ${isUpperCase ? "uppercase" : ""
        }`}
    >
      {title}
    </p>
    <p className="text-[14px] font-[500] text-black font-roboto">{count}</p>
  </div>
);

const viewTitles = ["No. of Policy", "Monthly Premium Report", "Cover"];

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.loginSlice?.data);
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [viewStage, setViewStage] = useState(0);
  const [policyInfo, setPolicyInfo] = useState(null);
  const handleRightClick = () =>
    setViewStage((prev) => (prev < viewTitles.length - 1 ? prev + 1 : 0));
  const handleLeftClick = () =>
    setViewStage((prev) => (prev > 0 ? prev - 1 : viewTitles.length - 1));

  const RenderViewContent = useCallback(() => {
    switch (viewStage) {
      case 0:
        return (
          <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
            {policyInfo?.policy?.length > 0 ? (
              policyInfo?.policy?.map((policy) => (
                <StatDisplay
                  isUpperCase={true}
                  key={policy?.type}
                  title={policy?.type}
                  count={policy?.count}
                />
              ))
            ) : (
              <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
                No Data Found
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <>
            {policyInfo?.premiumReport?.length > 0 ?
              <>
                <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
                  <p className="text-[16px] font-[700] text-black font-roboto">
                    Policy Month Wise
                  </p>
                  {policyInfo?.premiumReport?.map((month) => (
                    <StatDisplay
                      key={month?.monthName}
                      title={month?.monthName}
                      count={month?.policyCount}
                    />
                  ))}
                </div>
                <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
                  <p className="text-[16px] font-[700] text-black font-roboto">
                    Premium Month Wise
                  </p>
                  {policyInfo?.premiumReport?.map((month) => (
                    <StatDisplay
                      key={month?.monthName}
                      title={month?.monthName}
                      count={`₹${formatIndianNumber(month?.totalPremium)}`}
                    />
                  ))}
                </div>
              </>
              :
              <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
                <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
                  No Data Found
                </div>
              </div>
            }

          </>
        );
      case 2:
        return (
          <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
            {policyInfo?.cover?.length > 0 ? (
              policyInfo?.cover?.map((cover) => (
                <StatDisplay
                  isUpperCase={true}
                  key={cover?.type}
                  title={cover?.type}
                  count={`₹${formatIndianNumber(cover?.totalSumInsured)}`}
                />
              ))
            ) : (
              <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
                No Data Found
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  }, [viewStage, policyInfo]);

  const fetchingDetails = async (viewStage) => {
    if (initialLoading) {
      setLoading(false);
      setInitialLoading(true);
    } else {
      setLoading(true);
    }
    let params = new URLSearchParams();
    params.append("type", policyCategory[viewStage]);

    let res = await getClientProfile(userDetails?._id, params);
    if (res?.status === 200) {
      dispatch(updatedData({ ...userDetails, policyInfo: res?.data?.policyDetail ?? null }))
      if (res?.data?.premiumReport?.length > 0) {
        // let months = Object.keys(policyMonthCounts)?.flatMap((el) =>
        //   res?.data?.premiumReport?.map((r) =>
        //     policyMonthCounts[el] === r?.month ? {
        //       ...r,
        //       monthName: el
        //     } : {
        //       "totalPremium": 0,
        //       "policyCount": 0,
        //       "month": policyMonthCounts[el],
        //       monthName: el
        //     }
        //   )
        // );

        let months = Object.keys(policyMonthCounts).map((el) => {
          const matchedMonth = res?.data?.premiumReport?.find(
            (r) => policyMonthCounts[el] === r?.month
          );
          return matchedMonth
            ? { ...matchedMonth, monthName: el }
            : {
              totalPremium: 0,
              policyCount: 0,
              month: policyMonthCounts[el],
              monthName: el,
            };
        });

        console.log(months, 'monthsmonthsmonths')
        setPolicyInfo({ ...res?.data, 'premiumReport': months });
        setLoading(false);
        return;
      }
      setPolicyInfo(res?.data);
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
    }
    setInitialLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchingDetails(viewStage);
  }, [viewStage]);

  if (initialLoading) {
    return (
      <div
        style={{ height: "calc(100vh - 99px)" }}
        className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
      >
        <Loader color={"#688540"} size={44} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[12px]">
      <p className="text-[24px] font-[700] text-left text-green-theme font-roboto">
        Welcome
        <span className="text-black">
          , {userDetails?.firstName} {userDetails?.middleName} {userDetails?.lastName}
        </span>
      </p>
      <div className="bg-white rounded-[20px] flex flex-col justify-center items-center p-[24px] gap-[16px]">
        <Avatar size={200} icon={<UserOutlined />} src={userDetails?.profile ?? null} />
        <div>
          <p className="text-[24px] font-[400] text-center text-black font-roboto">
            {userDetails?.firstName} {userDetails?.middleName} {userDetails?.lastName}
          </p>
          <p className="text-[14px] font-[400] text-center text-black font-roboto">
            {userDetails?.permanentAddress?.city},{" "}
            {userDetails?.permanentAddress?.state}
          </p>
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              {policyInfo?.policyDetail?.totalPolicies ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              No. of Policy
            </p>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              ₹
              {formatIndianNumber(
                policyInfo?.policyDetail?.totalPremiumAmount
              ) ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              Total Premium
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-[18px] font-[600]  text-black font-roboto">
              {policyInfo?.policyDetail?.totalRaisedClaims ?? 0}
            </p>
            <p className="text-[14px] font-[400]  text-black font-roboto">
              Total Claim
            </p>
          </div>
        </div>

        <p
          onClick={() => navigate("/myProfile")}
          className="text-[14px] font-[500] text-center text-green-theme font-inter underline cursor-pointer"
        >
          See Full Profile
        </p>
      </div>

      <div
        onClick={() => navigate("/myPolicy")}
        className="h-[60px] bg-green-theme rounded-[16px] flex justify-between items-center px-[16px] cursor-pointer text-[14px] font-[400] text-white font-roboto"
      >
        <p>View All Policy </p>
        <RightOutlined />
      </div>

      <div className="flex justify-between items-center">
        <div onClick={handleLeftClick}>
          <LeftOutlined />
        </div>
        <p className="text-[20px] font-[500] text-black font-roboto">
          {viewTitles[viewStage]}
        </p>
        <div onClick={handleRightClick}>
          <RightOutlined />
        </div>
      </div>
      {loading ? (
        <div className="h-[220px] bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center">
          <Loader color={"#688540"} size={24} />
        </div>
      ) : (
        <RenderViewContent />
      )}
    </div>
  );
};

export default Home;
