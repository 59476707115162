import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: "loginSlice",
    initialState: {
        data: null,
        token: null,
    },
    reducers: {
        loginAuth: (state, actions) => {
            if (actions?.payload) {
                state.data = actions.payload?.data;
                state.token = actions.payload?.token;
            }
        },
        updatedToken: (state, actions) => {
            if (actions?.payload) {
                state.token = actions.payload?.token;
            }
        },
        updatedData: (state, actions) => {
            if (actions?.payload) {
                state.data = actions.payload;
            }
        },
        logout: (state, actions) => {
            if (actions) {
                state.data = null;
                state.token = null;
            }
        },
    },
});

export const { loginAuth, updatedToken, updatedData, logout } = loginSlice.actions;
export default loginSlice.reducer;
