import Feedback from "../modules/feedback";
import Home from "../modules/home/Home";
import MyPolicy from "../modules/myPolicy";
import ViewPolicy from "../modules/myPolicy/ViewPolicy";
import MyProfile from "../modules/myProfile";

export const PrivatePaths = [
  {
    path: "/home",
    component: <Home />,
  },
  {
    path: "/myPolicy",
    component: <MyPolicy />,
  },
  {
    path: "/viewPolicy",
    component: <ViewPolicy />,
  },
  {
    path: "/myProfile",
    component: <MyProfile />,
  },
  {
    path: "/feedback",
    component: <Feedback />,
  },
];
