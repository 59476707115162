import React from "react";
import logo from "../assets/images/logo.png";
import { HeaderMenuIcon } from "../utils/SVG";
const Header = ({ showDrawer }) => {
  return (
    <div className="bg-green-theme w-full h-[100%] flex justify-between px-[16px] items-center">
      <img src={logo} alt="" className="w-[135px] h-[35px]" />
      <div className="cursor-pointer" onClick={showDrawer}>
        <HeaderMenuIcon />
      </div>
    </div>
  );
};

export default Header;
