import Login from "../modules/auth/Login";
import TermAndConditions from "../modules/auth/TermAndConditions";
import VerificationOtp from "../modules/auth/VerificationOtp";

const isTermAndConditionAccpected = true;
export const PublicPaths = [
  // {
  //   path: "/termAndConditions",
  //   component: <TermAndConditions />,
  // },
  isTermAndConditionAccpected && {
    path: "/",
    component: <Login />,
  },
  isTermAndConditionAccpected && {
    path: "/otp",
    component: <VerificationOtp />,
  },
];
