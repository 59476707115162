import s3 from "./awsConfig";
import { Notification } from "../components/Notification";

export const uploadDocOnS3 = async (selectedFile) => {
    if (!selectedFile) return;

    const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: `uploads/${Date.now()}_${selectedFile.name}`, // Unique key for each file
        Body: selectedFile,
        ContentType: selectedFile.type,
    };

    return new Promise((resolve, reject) => {
        s3.upload(params).send((err, data) => {
            if (err) {
                console.error("Upload failed:", err);
                Notification.error("Failed to upload file.");
                reject(err);
            } else {
                console.log("File uploaded successfully:", data.Location);
                resolve(data.Location); // Resolving with the file URL
            }
        });
    });
};
