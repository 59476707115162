import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatIndianNumber, getAcademicYear } from '../../utils/commonFunc';
import dayjs from 'dayjs';
import { Loader } from '../../components/Loader';
import { Divider } from 'antd';

function Claim({ data, initialLoading }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);

    const handlePolicyClick = (policy) => {
        navigate("/viewPolicy", { state: { policy } });
    };


    useEffect(() => {
        setLoading(true);
        let filteredFamilyPolicy = data?.filter((el) => el?.raiseClaim)?.map((el) => ({
            id: el?._id,
            policyNo: el?.policyNumber,
            type: el?.policyOwner,
            status: "Claim",
            details: [
                { label: "Name", value: el?.familyMemberDetails?.name ?? '' },
                { label: "Relation", value: el?.familyMemberDetails?.relation ?? '' },
                { label: "Premium Freq.", value: el?.premiumFrequency },
                { label: "Type", value: el?.type },
                { label: "Year", value: getAcademicYear(el?.year) },
                { label: "Premium Amt.", value: formatIndianNumber(el?.premiumAmount) },
                { label: "Sum Insured", value: formatIndianNumber(el?.sumInsured) },
                { label: "End Date", value: dayjs(el?.maturityEndDate).format('DD/MM/YYYY') },
                { label: "Next Due Date", value: dayjs(el?.maturityEndDate).format('DD/MM/YYYY') },
                { label: "Policy Start", value: dayjs(el?.policyStart).format('DD/MM/YYYY') },
            ],
        }));
        setPolicies(filteredFamilyPolicy);
        setLoading(false);
    }, []);


    if (initialLoading || loading) {
        return <div
            style={{ height: "calc(100vh - 283px)" }}
            className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
        >
            <Loader color={"#688540"} size={44} />
        </div>
    }

    if (policies?.length === 0) {
        return <div
            style={{ height: "calc(100vh - 283px)" }}
            className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col align-center justify-center"
        >
            <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
                No Claim Found
            </div>
        </div>
    }



    return (
        <div className="flex flex-col gap-[10px]">
            {policies.map((policy, policyIndex) => (
                <div
                    key={policyIndex}
                    className="bg-white p-[16px] rounded-[6px]"
                    onClick={() => handlePolicyClick(policy)}
                >
                    <div className="flex justify-between">
                        <div className="flex flex-col gap-4px]">
                            <p className="text-[14px] font-[400] text-gray-500 font-roboto">
                                Policy No.
                            </p>
                            <p className="text-[14px] font-[500] text-black font-roboto">
                                {policy.policyNo}
                            </p>
                        </div>
                        <div
                            className={`${policy.status === "Claim" && "bg-[#FFF3DB]"} rounded-[100px] px-[12px] py-[8px] h-[24px] w-[fit-content] flex justify-center items-center`} >
                            <p className={`text-[12px] font-[600] ${policy.status === "Claim" && "text-[#FFC023]"} font-roboto`} >
                                {policy.status}
                            </p>
                        </div>
                    </div>

                    <Divider
                        style={{
                            margin: "16px 0px",
                            height: "1px",
                            backgroundColor: "#E5E5E5",
                        }}
                    />

                    <div className="flex flex-col gap-[16px]">
                        {policy?.type === 'family' && <div className="flex justify-between">
                            {policy.details.slice(0, 2).map((detail, index) => (
                                <div key={index} className="flex flex-col gap-4px]">
                                    <p className="text-[14px] font-[500] text-black font-roboto">
                                        {detail.label}
                                    </p>
                                    <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize text-center">
                                        {detail.value}
                                    </p>
                                </div>
                            ))}
                        </div>}

                        <div className="flex justify-between">
                            {policy.details.slice(2, 4).map((detail, index) => (
                                <div key={index} className="flex flex-col gap-4px]">
                                    <p className="text-[14px] font-[500] text-black font-roboto">
                                        {detail.label}
                                    </p>
                                    <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize ">
                                        {detail.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between">
                            {policy.details.slice(4, 7).map((detail, index) => (
                                <div key={index} className="flex flex-col gap-4px]">
                                    <p className="text-[14px] font-[500] text-black font-roboto">
                                        {detail.label}
                                    </p>
                                    <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize text-center">
                                        {detail.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Divider
                        style={{
                            margin: "16px 0px",
                            height: "1px",
                            backgroundColor: "#E5E5E5",
                        }}
                    />

                    <div className="flex justify-between">
                        {policy.details.slice(7, 10).map((detail, index) => (
                            <div key={index} className="flex flex-col gap-4px]">
                                <p className="text-[14px] font-[500] text-black font-roboto">
                                    {detail.label}
                                </p>
                                <p className="text-[14px] font-[400] text-gray-500 font-roboto capitalize text-center">
                                    {detail.value}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))
            }
        </div >
    )
}

export default Claim