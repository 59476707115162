import { ToastContainer } from "react-toastify";
import TermAndConditions from "./modules/auth/TermAndConditions";
import HealthInsuranceRoutes from "./routes/HealthInsuranceRoutes";

function App() {
  const isTermAndConditionAccpected = localStorage.getItem("isTCAccpected");

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        style={{ width: '100%', top: '8px' }}
      />

      {isTermAndConditionAccpected ? (
        <HealthInsuranceRoutes />
      ) : (
        <TermAndConditions />
      )}
    </>
  );
}

export default App;
