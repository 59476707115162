import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { PublicPaths } from "./PublicPaths";
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import { PrivatePaths } from "./PrivatePaths";
import { useSelector } from "react-redux";

// This function is used for Authentication when user's credentials will be valid.
function PublicRoute({ isAuthenticated }) {
  if (isAuthenticated !== null) {
    return <Navigate to="/home" replace />;
  }
  return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  if (isAuthenticated === null) return <Navigate to="/" />;
  return <Outlet />;
}

export default function HealthInsuranceRoutes() {
  const credentials = useSelector((state) => state?.loginSlice?.token);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoute isAuthenticated={credentials} />}>
          <Route path="/" element={<PublicLayout />}>
            {PublicPaths?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>

        <Route element={<PrivateRoute isAuthenticated={credentials} />}>
          <Route path="/" element={<PrivateLayout />}>
            {PrivatePaths?.map((routes, index) => (
              <Route
                key={index}
                path={routes.path}
                element={routes.component}
              />
            ))}
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter >
  );
}
