import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { Input } from "antd";
import { ButtonComp } from "../../components/ButtonComp";
import { PhoneIcon } from "../../utils/SVG";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../components/Notification";
import { getErrorMessage } from "../../utils/errorMessage";
import { login } from "../../service/Collections";
export default function Login() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const getPhoneNumber = (e) => {
    const cleanedVal = e.target.value?.replace(/\D/g, "");
    if (e.target.value !== cleanedVal) {
      Notification.error("Only numeric characters are allowed!");
    }

    setPhone(cleanedVal);
  };

  const handleGetOtp = async () => {
    if (phone.length > 10) {
      Notification.warning("Phone Number should be 10 digits only!");
      return;
    }

    setLoading(true);
    let values = { phoneNumber: phone };
    let res = await login(values);
    if (res?.status === 200) {
      Notification.success(res?.message);
      setLoading(false);
      navigate("/otp", { state: phone });
    } else {
      const message = getErrorMessage(res, "Failed to connection");
      Notification.error(message);
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex justify-around items-center flex-col px-[16px]">
      <img src={logo} alt="" className="w-[236px] h-[60px]" />

      <div className="bg-white/10 backdrop-blur-sm py-[32px] px-[16px] flex flex-col gap-[32px] rounded-[16px]">
        <div className="flex flex-col gap-[8px]">
          <p className="text-[32px] font-[700] leading-[38px] text-center text-white font-roboto">
            Insurance Makes Your Life Better
          </p>
          <p className="text-[14px] font-[600] leading-[16px] text-center text-white font-roboto">
            Welcome back to A K Investment. Have a good time
          </p>
        </div>
        <div className="flex flex-col gap-[24px]">
          <Input
            type="number"
            placeholder="Phone Number"
            className="h-[48px]"
            prefix={<PhoneIcon />}
            maxLength={10}
            onChange={getPhoneNumber}
            value={phone}
          />
          <ButtonComp
            textcard={loading ? "Loading..." : "Get OTP"}
            handleAction={!loading && handleGetOtp}
          />
        </div>
      </div>

      <p className="text-[12px] font-[600] text-center text-white font-roboto">
        Terms & Condition | Privacy policy{" "}
      </p>
    </div>
  );
}
