import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const MedicalHistory = () => {
  const [medicalHistory, setMedicalHistory] = useState([]);
  const userDetails = useSelector((state) => state?.loginSlice?.data?.medicalHistory);

  const openInNewWindow = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  useEffect(() => {
    let filterMedicalHistory = userDetails?.map((el) => el?.type === 'client' && el)
    setMedicalHistory(filterMedicalHistory)
  }, []);

  return (
    <div>
      <p className="text-[24px] font-[700] text-left text-black font-roboto mb-[10px] mt-[12px]">
        Medical History
      </p>
      {medicalHistory?.length > 0 ? medicalHistory?.map((el) => (
        <div className="bg-white rounded-[12px] p-[20px] flex flex-col gap-[16px]">
          <p className="text-[14px] font-[700] text-black font-roboto">
            Client Medical History
          </p>

          <div className="flex justify-between">
            <p className="text-[14px] font-[400] text-gray-500 font-roboto">
              Medical illness
            </p>
            <p className="text-[14px] font-[400] text-black font-roboto">
              {el?.illness}
            </p>
          </div>
          <Divider
            style={{
              margin: "0px",
              height: "1px",
              backgroundColor: "#E5E5E5",
            }}
          />
          <div className="flex justify-between">
            <p className="text-[14px] font-[400] text-gray-500 font-roboto">
              Medicine Name
            </p>
            <p className="text-[14px] font-[400] text-black font-roboto">
              {el?.medicineName}
            </p>
          </div>
          {el?.document &&
            <p className="text-[14px] font-[500] text-green-theme font-roboto underline" onClick={() => openInNewWindow(el?.document)} >
              View Documents
            </p>
          }
        </div>
      ))
        :
        <div className="bg-white p-[17px] rounded-[14px] gap-[16px] flex flex-col">
          <div className="text-[24px] font-[600] text-gray-400 text-center font-roboto py-8">
            No Medical History Found
          </div>
        </div>
      }
    </div>
  );
};

export default MedicalHistory;
